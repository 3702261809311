import React from 'react';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT, DEFAULT_TIME_ZONE, allDateFormats, allTimeFormats, timeZoneList, } from '@corti/date';
import { intl } from '@corti/i18n';
import { ClientSettingsKeys, } from '@corti/lib/coreApiService';
import { trackerService } from 'browser/services/init';
import { Autocomplete, TextField } from 'lib/cortiUI';
import { SettingsSection } from './SettingsSection';
import { ToggleSwitch } from './ToggleSwitch';
const DEFAULT_APP_NAME_OPTIONS_LIST = [
    {
        id: 'home',
        title: () => intl.t('home:appName', 'Home'),
    },
    {
        id: 'real-time',
        title: () => intl.t('realtime:appName', 'Triage'),
        requiresOrgFeature: 'real-time',
    },
    {
        id: 'explore',
        title: () => intl.t('explore:appName', 'Explore'),
        requiresOrgFeature: 'review',
    },
    { id: 'editorv2', title: () => 'Protocol Editor', requiresOrgFeature: 'protocol-graph-editor' },
    {
        id: 'performance',
        title: () => intl.t('performanceApp:appName', 'Compliance'),
        requiresOrgFeature: 'performance-metrics',
    },
    {
        id: 'search',
        title: () => intl.t('searchApp:appName', 'Search'),
        requiresOrgFeature: 'mission-control-search-view',
    },
    {
        id: 'tasks',
        title: () => intl.t('tasksApp:appName', 'Tasks'),
        requiresOrgFeature: 'mission-control-task-list',
    },
];
const getOption = (value, settingsKey) => ({
    value,
    label: value,
    settingsKey,
});
export const ClientSettings = ({ clientSettings, organizationFeatures = [], onUpdateClientSettings, }) => {
    var _a;
    const dateFormatOptions = allDateFormats.map((format) => getOption(format, ClientSettingsKeys.dateFormat));
    const timeFormatOptions = allTimeFormats.map((format) => getOption(format, ClientSettingsKeys.timeFormat));
    const timeZonesOptions = timeZoneList.map((timeZone) => getOption(timeZone, ClientSettingsKeys.timeZone));
    //Adding local time zone option
    const localTimezoneOption = {
        value: undefined,
        label: intl.t(`adminPanel:clientSettings.localTimeZone`, 'Local Timezone'),
        settingsKey: ClientSettingsKeys.timeZone,
    };
    timeZonesOptions.unshift(localTimezoneOption);
    const defaultDateFormat = dateFormatOptions.find((o) => o.value === DEFAULT_DATE_FORMAT);
    const defaultTimeFormat = timeFormatOptions.find((o) => o.value === DEFAULT_TIME_FORMAT);
    const defaultTimeZone = timeZonesOptions.find((o) => o.value === DEFAULT_TIME_ZONE);
    const defaultAppNameOptions = DEFAULT_APP_NAME_OPTIONS_LIST.filter((appModule) => appModule.requiresOrgFeature
        ? organizationFeatures.find((feature) => feature.featureID === appModule.requiresOrgFeature)
        : true).map(({ id, title }) => ({
        value: id,
        label: title ? title() : id,
        settingsKey: ClientSettingsKeys.defaultAppName,
    }));
    const DEFAULT_APP_NAME = defaultAppNameOptions.find((o) => o.value === 'home');
    const handleSelectValueChange = async ({ settingsKey, value }) => {
        await onUpdateClientSettings(settingsKey, value);
        trackerService.track('[Admin panel] Organization Client settings changed', {
            field: settingsKey,
            value: value,
        });
    };
    return (React.createElement(SettingsSection, null,
        React.createElement(Autocomplete, { size: "small", options: defaultAppNameOptions, getOptionLabel: (option) => option.label, defaultValue: DEFAULT_APP_NAME, value: defaultAppNameOptions.find((appModule) => appModule.value === clientSettings[ClientSettingsKeys.defaultAppName]), onChange: (_, option) => {
                if (option) {
                    void handleSelectValueChange(option);
                }
            }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t('adminPanel:clientSettings.mainPage', 'Main page'), placeholder: intl.t('adminPanel:clientSettings.mainPage', 'Main page'), helperText: intl.t('adminPanel:clientSettings.mainPageInputHelperText', ' The page that a user will be redirected to after logging in') }))), clearIcon: null }),
        React.createElement(Autocomplete, { size: "small", options: dateFormatOptions, getOptionLabel: (option) => option.label, defaultValue: defaultDateFormat, value: dateFormatOptions.find((dateFormat) => dateFormat.value === clientSettings[ClientSettingsKeys.dateFormat]), onChange: (_, option) => {
                if (option) {
                    void handleSelectValueChange(option);
                }
            }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t(`adminPanel:clientSettings.dateFormat`, 'Date format'), placeholder: intl.t(`adminPanel:clientSettings.dateFormat`, 'Date format') }))), clearIcon: null }),
        React.createElement(Autocomplete, { size: "small", options: timeFormatOptions, getOptionLabel: (option) => option.label, defaultValue: defaultTimeFormat, value: timeFormatOptions.find((timeFormat) => timeFormat.value === clientSettings[ClientSettingsKeys.timeFormat]), onChange: (_, option) => {
                if (option) {
                    void handleSelectValueChange(option);
                }
            }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t(`adminPanel:clientSettings.timeFormat`, 'Time format'), placeholder: intl.t(`adminPanel:clientSettings.timeFormat`, 'Time format') }))), clearIcon: null }),
        React.createElement(Autocomplete, { size: "small", options: timeZonesOptions, getOptionLabel: (option) => option.label, defaultValue: localTimezoneOption, value: timeZonesOptions.find((timeZone) => timeZone.value === clientSettings[ClientSettingsKeys.timeZone]), onChange: (_, option) => {
                if (option) {
                    void handleSelectValueChange(option);
                }
            }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { label: intl.t(`adminPanel:clientSettings.timeZone`, 'Timezone'), placeholder: intl.t(`adminPanel:clientSettings.timeZone`, 'Timezone'), helperText: `${intl.t('adminPanel:clientSettings.timezoneInputHelperText', 'Local timezone')}: ${defaultTimeZone === null || defaultTimeZone === void 0 ? void 0 : defaultTimeZone.label}` }))), clearIcon: null }),
        React.createElement(ToggleSwitch, { label: intl.t(`adminPanel:clientSettings.allowSessionRecording`, 'Allow Session Recording'), checked: (_a = clientSettings.allowSessionRecording) !== null && _a !== void 0 ? _a : false, onChange: (_, checked) => {
                void handleSelectValueChange({
                    settingsKey: ClientSettingsKeys.allowSessionRecording,
                    value: checked,
                    label: '',
                });
            } })));
};
