import React from 'react';
import { useFilters } from './useFilters';
import { usePagination } from './usePagination';
import { useSort } from './useSort';
import { useVisibilitySettings } from './useVisibilitySettings';
import { useVisitedCalls } from './useVisitedCalls';
const initialState = {
    pagination: {},
    visitedCalls: {},
    visibilitySettings: {},
    filters: {},
    sort: {},
    totalItems: {
        value: 0,
        set: () => { },
    },
};
const SearchStateContext = React.createContext(initialState);
export const SearchStateProvider = ({ children }) => {
    var _a;
    const visitedCalls = useVisitedCalls();
    const pagination = usePagination();
    const visibilitySettings = useVisibilitySettings();
    const filters = useFilters();
    const sort = useSort();
    const [totalItems, setTotalItems] = React.useState(initialState.totalItems.value);
    const value = React.useMemo(() => ({
        pagination,
        visitedCalls,
        visibilitySettings,
        filters,
        sort,
        totalItems: {
            value: totalItems,
            set: setTotalItems,
        },
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.page, visitedCalls, (_a = filters.filterModel) === null || _a === void 0 ? void 0 : _a.items]);
    return React.createElement(SearchStateContext.Provider, { value: value }, children);
};
export const useSearchState = () => {
    const context = React.useContext(SearchStateContext);
    if (!context) {
        throw new Error('useSearchState must be used within a SearchStateProvider');
    }
    return context;
};
